<template>
  <div class="page half-width with-right-fixed-content script-page">
    <div class="page-content page-script-content">
      <div class="page-header">
        <div class="page-header_left">
          <div class="page-header_back-link" @click="$router.back()">
            <div class="icon icon-arrow"></div>
            <span>Назад</span>
          </div>
          <div class="script-edit-bar">
            <div class="page-title">
              <template v-if="isNewScript">Новый скрипт</template>
              <template v-else>
                <v-skeleton-loader v-if="scriptLoading" type="heading" width="400"/>
                <span v-else>{{ script.title }}</span>
              </template>
            </div>
            <div class="script-edit-bar_buttons" v-if="!isNewScript">
              <div class="icon icon-trash" @click="$refs['confirm-remove'].open()"/>
              <div class="icon icon-pencil" @click="editTitleModalOpen"/>
            </div>
          </div>
        </div>
      </div>
      <v-text-field
        v-if="isNewScript"
        label="Название"
        persistent-placeholder
        placeholder="Первое собеседование"
        v-model="script.title"
        class="input-script-title"
        hide-details="auto"
      />
      <div class="question-list">
        <v-skeleton-loader type="image" v-if="scriptLoading" width="500" height="170" style="border-radius: 12px"/>
        <draggable v-else item-key="id" v-model="questions">
          <div
            v-for="(element, index) in questions"
            :key="index"
            class="question-list_item">
            <div
              :id="element.name"
              class="question-list_item_remove-icon icon icon-trash"
              @click="removeQuestion(index)"
            />
            <v-icon class="drag-icon">mdi-drag</v-icon>
            <v-text-field
              :label="`Вопрос ${index + 1}`"
              v-model="element.question"
              class="question-list_item_title"
              hide-details
              persistent-placeholder
            >
            </v-text-field>
            <div class="question-list_options">
              <div
                class="question-list_option"
                v-for="(option, index) in element.options"
                :key="index"
              >
                <div class="question-list_option_number">{{ index + 1 }}</div>
                <input
                  class="question-list_option_input"
                  v-model="element.options[index]"
                  placeholder="Введите вариант ответа.."
                >
                <div
                  class="question-list_option_remove-icon icon icon-close"
                  @click="removeOption(element, index)"
                />
              </div>
            </div>
            <div class="add-option-button transparent-button" @click="addOption(element)">
              <div class="icon icon-new"></div>
              <span>Добавить вариант ответа</span>
            </div>
          </div>

        </draggable>
      </div>
      <div>
        <div class="add-new-question transparent-button" @click="addQuestion()">
          <div class="icon icon-new"></div>
          <span>Добавить вопрос</span>
        </div>
      </div>
      <div>
        <v-btn
          class="default-button submit-button"
          color="primary"
          :disabled="!checkData"
          :loading="loading"
          @click="submit">
          Готово
        </v-btn>
      </div>
    </div>
    <div class="page-right-content">
      <HelpInfo
        class="script-help-info"
        :onceShow="false"
        text="Скрипты помогут вам заранее подготовить список вопросов для собеседования. Вы также можете добавлять варианты ответа."
      />
    </div>
    <ModalWithFields
      ref="edit-title-modal"
      @submit="({title}) => script.title = title"
    />
    <Confirm ref="confirm-remove" message="Удалить скрипт?" @accept="removeScript"/>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import HelpInfo from '@/components/HelpInfo.vue';

export default {
  name: 'Script',
  components: {
    HelpInfo,
    draggable
  },
  props: {
    scriptId: [Number, String],
  },
  data() {
    return {
      script: {},
      questions: [],
      scriptLoading: false,
      loading: false,
    };
  },
  methods: {
    removeScript() {
      this.$server.request2(`form/remove/${this.scriptId}`, {}, (data)=>{
        this.$router.replace('/scripts');
      });
    },
    addQuestion(questionParams = {}) {
      this.questions.push({
        question: '',
        options: [],
        ...questionParams,
      });
    },
    removeQuestion(index) {
      this.questions.splice(index, 1);
    },
    addOption(question) {
      question.options.push('');
    },
    removeOption(question, optionIndex) {
      question.options.splice(optionIndex, 1);
    },
    editTitleModalOpen() {
      this.$refs['edit-title-modal'].open(this.fieldTitle);
    },
    loadItem() {
      this.scriptLoading = true;
      this.$server.request2(`form/get/${this.scriptId}`, {}, (data)=>{
        this.scriptLoading = false;
        this.script = data.response;
        const { questions } = this.script;
        if (questions && Array.isArray(questions) && questions.length) {
          this.questions = [];
          this.script.questions.forEach(this.addQuestion);
        }
      }, ()=>{
        this.scriptLoading = false;
      })
    },
    submit() {
      if (this.checkData) {
        this.questions = this.questions.filter(({
          question,
          options,
        }) => question || options.length);
        const data = {
          ...this.script,
          questions: this.questions,
        };
        const url = this.isNewScript ? 'form/create' : `form/update/${this.scriptId}`;
        this.loading = true;
        this.$server.request2(url, data, (data)=>{
          this.loading = false;
          this.$router.replace('/scripts');
        }, ()=>{
          this.loading = false;
        })
      }
    },
    clear() {
      this.script = {};
      this.questions = [];
    },
    onOpen() {
      this.clear();
      this.addQuestion();
    },
  },
  mounted() {
    if (!this.isNewScript) {
      this.loadItem();
    }
  },
  computed: {
    checkData() {
      return !!this.script.title;
    },
    isNewScript() {
      return this.scriptId === 'new';
    },
    fieldTitle() {
      return [{
        title: 'Название',
        name: 'title',
        value: this.script.title,
      }];
    },
  },
  created() {
    this.$watch(
      () => this.scriptId,
      () => {
        if (this.scriptId) {
          this.onOpen();
        }
      },
    );
    this.onOpen();
  },
};
</script>

<style scoped lang="scss">
.page {
  --page-width: 500px;
}

.page-header_left {
  width: 100%;
}

.script-edit-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &_buttons {
    display: flex;
    align-items: center;

    & > .icon {
      padding: 14px;
      font-size: 24px;
      color: rgba(var(--page-color-main-rgb), .25);
      cursor: pointer;

      &:last-child {
        padding-right: 0;
      }
    }
  }
}

.page-right-content {
  padding-top: 60px;
  &::v-deep(.help-info-block) {
    box-sizing: content-box;
    width: 280px;
    padding: 24px;
    flex-direction: column;

    .help-info-block_text {
      margin-top: 8px;
      padding: 0;
    }
  }
}

.question-list {
  margin-top: 40px;
  margin-bottom: 24px;

  &_item {
    margin-bottom: 30px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &_item {
    position: relative;
    width: 100%;
    padding: 28px;
    padding-top: 56px;
    background: #FFFFFF;
    box-shadow: 2px 8px 30px rgba(0, 0, 0, 0.2);
    border-radius: 12px;

    .add-option-button {
      width: auto;
      display: inline-flex;
      align-items: center;

      .icon {
        margin-right: 6px;
        font-weight: 600;
        font-size: 12px;
      }
    }

    &_remove-icon {
      position: absolute;
      top: 0;
      right: 0;
      font-size: 16px;
      padding: 24px;
      cursor: pointer;
      color: rgba(var(--page-color-main-rgb), .25);
    }
    .drag-icon {
      position: absolute;
      top: 0;
      left: 0;
      padding: 16px;
      cursor: grab;
    }
  }

  &_options {
    margin-top: 26px;
    margin-bottom: 26px;

    & > * {
      margin-bottom: 28px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &_option {
    display: flex;
    align-items: center;

    &_number {
      flex-shrink: 0;
      margin-right: 10px;
      font-weight: 600;
      font-size: 15px;
      line-height: 20px;
      letter-spacing: -0.25px;
    }

    &_input {
      width: 100%;
      font-size: 15px;
      line-height: 20px;
      letter-spacing: -0.25px;
    }

    &_remove-icon {
      cursor: pointer;
      padding: 4px 0 4px 12px;
      font-size: 12px;
      color: rgba(var(--page-color-main-rgb), .25);
    }
  }
}

.add-new-question {
  margin-bottom: 38px;
  width: auto;
  display: inline-flex;
  align-items: center;
  color: var(--page-color-main);

  .icon {
    margin-right: 6px;
    font-weight: 600;
    font-size: 12px;
  }
}

.submit-button {
  width: auto;
  min-width: 150px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 1160px ) {
  .page-script-content.page-content {
    padding-left: 40px;
    padding-right: 40px;
    min-width: 620px;
  }
  .page-right-content {
    .script-help-info {
      width: 240px;
      padding: 16px;
    }
  }
}
</style>
