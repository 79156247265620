<template>
  <div v-if="show" class="help-info-block" :class="classes">
    <svg class="help-info-block_icon" fill="none" height="18" viewBox="0 0 18 18" width="18"
         xmlns="http://www.w3.org/2000/svg">
      <path clip-rule="evenodd"
            d="M0 9C0 4.02944 4.02944 0 9 0C13.9706 0 18 4.02944 18 9C18 13.9706 13.9706 18 9 18C4.02944 18 0 13.9706 0 9ZM10.3895 5.08159C10.3895 5.84925 9.76715 6.47156 8.99949 6.47156C8.23182 6.47156 7.60951 5.84925 7.60951 5.08159C7.60951 4.31393 8.23182 3.69162 8.99949 3.69162C9.76715 3.69162 10.3895 4.31393 10.3895 5.08159ZM10.3927 8.78872C10.3927 8.40489 10.0815 8.09373 9.6977 8.09373L8.27598 8.09419C7.8924 8.10818 7.59279 8.43046 7.60678 8.81404L7.89648 13.5092C7.90423 13.7217 7.95851 13.9107 8.05105 14.0682C8.1436 14.2258 8.27442 14.3519 8.43525 14.4387C8.59607 14.5254 8.78691 14.5728 8.9995 14.5728C9.21209 14.5728 9.40292 14.5254 9.56375 14.4387C9.72458 14.3519 9.8554 14.2258 9.94794 14.0682C10.0405 13.9107 10.0948 13.7217 10.1025 13.5092L10.3922 8.81404C10.3925 8.8056 10.3927 8.79716 10.3927 8.78872Z"
            fill="#111111"
            fill-rule="evenodd"/>
    </svg>
    <div class="help-info-block_text">{{ text }}</div>
    <div class="help-info-block_close icon icon-close" @click="hide" v-if="closeButton"></div>
  </div>
</template>

<script>
export default {
  name: 'HelpInfo',
  props: {
    name: {
      type: String,
    },
    text: String,
    onceShow: {
      type: Boolean,
      default: false,
    },
    closeButton: {
      type: Boolean,
      default: false,
    },
    classes: String,
  },
  data() {
    return {
      show: true,
      ShownHelpInfoList: (window.localStorage.getItem('ShownHelpInfoList') && JSON.parse(window.localStorage.getItem('ShownHelpInfoList'))) || [],
    };
  },
  methods: {
    hide() {
      this.show = false;
      if (this.name) {
        this.ShownHelpInfoList.push(this.name);
        window.localStorage.setItem('ShownHelpInfoList', JSON.stringify(this.ShownHelpInfoList));
      }
    },
  },
  created() {
    if (this.onceShow && this.name) {
      this.show = !this.ShownHelpInfoList.find((item) => item === this.name);
    }
  },
};
</script>

<style lang="scss">
.help-info-block {
  position: relative;
  display: flex;
  padding: 20px;
  background: var(--color-gray);
  border-radius: 12px;
  height: fit-content;

  &_icon {
    width: 18px;
    height: 18px;
    opacity: 0.25;
    margin-right: 18px;
    flex-shrink: 0;
  }

  &_text {
    width: 100%;
    font-size: 13px;
    line-height: 20px;
    padding-right: 60px;
  }

  &_close {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 12px;
    font-weight: bold;
    padding: 24px;
    cursor: pointer;
  }
}
</style>
